import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  writeBatch,
  query,
  where,
} from 'firebase/firestore';
import '../css/ConfirmationRequestsPage.css';
import LoadingSpinner from '../LoadingSpinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ConfirmationRequestsPage() {
  const [requests, setRequests] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const requestsCollection = collection(db, 'requests');
        const requestSnapshot = await getDocs(requestsCollection);
        const confirmationRequests = requestSnapshot.docs
          .filter((doc) => doc.data().status === 'confirmation')
          .map((doc) => ({ id: doc.id, ...doc.data() }));
        setRequests(confirmationRequests);
      } catch (error) {
        console.error('Ошибка загрузки заявок:', error);
        toast.error('Ошибка при загрузке заявок.', {
          position: 'top-center',
          autoClose: 3000,
        });
      } finally {
        setLoading(false);
      }
    };

    const fetchWorkers = async () => {
      try {
        const workersCollection = await getDocs(collection(db, 'workers'));
        setWorkers(workersCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error('Ошибка загрузки работников:', error);
        toast.error('Ошибка при загрузке работников.', {
          position: 'top-center',
          autoClose: 3000,
        });
      }
    };

    fetchRequests();
    fetchWorkers();
  }, []);

// Пример функции calculateShares
const calculateShares = (financialsData, assignedWorkers) => {
  const totalIncome = financialsData.reduce((sum, record) => {
    return record.type === 'income' ? sum + record.amount : sum;
  }, 0);

  const companyShare = totalIncome * 0.50;
  const perWorkerShare = (totalIncome * 0.50) / assignedWorkers.length;

  return {
    companyShare,
    perWorkerShare,
  };
};


const confirmRequest = async (id) => {
  try {
    const requestDoc = doc(db, 'requests', id);
    const archivedDoc = doc(db, 'archiveRequests', id);
    const requestSnapshot = await getDoc(requestDoc);

    if (requestSnapshot.exists()) {
      const requestData = requestSnapshot.data();
      const assignedWorkers = requestData.assignedWorkers || [];
      const requestId = id;

      if (assignedWorkers.length === 0) {
        toast.error(
          'Нет назначенных работников для этой заявки. Назначьте работников перед подтверждением.',
          {
            position: 'top-center',
            autoClose: 3000,
          }
        );
        return;
      }

      // Получаем все финансовые записи, относящиеся к заявке
      const financialsQuery = query(
        collection(db, 'financials'),
        where('requestId', '==', requestId)
      );
      const financialsSnapshot = await getDocs(financialsQuery);
      const financialsData = financialsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Расчет общего дохода
      const totalIncome = financialsData.reduce((sum, record) => {
        return record.type === 'income' ? sum + record.amount : sum;
      }, 0);

      console.log("Проверка общего дохода для архива:", totalIncome);

      // Проверка на отсутствие дохода для распределения
      if (totalIncome <= 0) {
        toast.error('Нет доходов для распределения.', {
          position: 'top-center',
          autoClose: 3000,
        });
        return;
      }

      // Получаем долю компании и работников
      const { companyShare, perWorkerShare } = calculateShares(
        financialsData,
        assignedWorkers
      );

      const batch = writeBatch(db);

      // Запись дохода компании
      const companyFinancialDocRef = doc(collection(db, 'transactions'));
      batch.set(companyFinancialDocRef, {
        requestId,
        type: 'income',
        amount: companyShare,
        description: `Доход от заявки ${requestId}`,
        date: new Date().toISOString(),
        auto: true,
      });

      // Запись доходов для каждого работника
      assignedWorkers.forEach((workerId) => {
        const workerFinancialDocRef = doc(collection(db, 'financials'));
        batch.set(workerFinancialDocRef, {
          workerId,
          requestId,
          type: 'income',
          amount: perWorkerShare,
          description: `Выплата за работу по заявке ${requestId}`,
          date: new Date().toISOString(),
          auto: true,
          source: 'company',
        });
      });

      // Устанавливаем баланс в архив, сбрасывая его, чтобы исключить дубли
      const archivedData = {
        ...requestData,
        archivedDate: new Date().toISOString(),
        balance: totalIncome, // Устанавливаем баланс как исходное значение totalIncome
      };

      // Запись архива с проверкой
      console.log("Устанавливаем баланс для архива:", archivedData.balance);
      await setDoc(archivedDoc, archivedData);

      // Удаление оригинальной заявки после архивирования
      await deleteDoc(requestDoc);
      setRequests((prev) => prev.filter((req) => req.id !== id));

      // Выполнение всех записей batch
      await batch.commit();

      toast.success('Заявка успешно подтверждена и перемещена в архив!', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  } catch (error) {
    console.error('Ошибка подтверждения заявки:', error);
    toast.error('Ошибка при подтверждении заявки.', {
      position: 'top-center',
      autoClose: 3000,
    });
  }
};



  if (loading) {
    return <LoadingSpinner />;
  }

  if (requests.length === 0) {
    return <p className="no-requests">Нет заявок на подтверждение.</p>;
  }

  return (
    <div className="confirmation-container">
      <ToastContainer />
      <h1 className="confirmation-title">Подтверждение заявок</h1>
      {requests.map((request) => (
        <div key={request.id} className="request-card">
          <p>
            <strong>Имя:</strong> {request.name}
          </p>
          <p>
            <strong>Телефон:</strong> {request.phone}
          </p>
          <p>
            <strong>Дата:</strong> {new Date(request.startDateTime).toLocaleString('ru-RU')}
          </p>
          <p>
            <strong>Модель авто:</strong> {request.carModel}
          </p>
          <p>
            <strong>Год авто:</strong> {request.carYear}
          </p>
          <p>
            <strong>Статус:</strong> Подтверждение
          </p>
          <button className="confirm-button" onClick={() => confirmRequest(request.id)}>
            Подтвердить
          </button>
        </div>
      ))}
    </div>
  );
}

export default ConfirmationRequestsPage;
