import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import {
  Layout,
  Row,
  Col,
  Card,
  Statistic,
  Spin,
  Input,
  Select,
  Space,
  Typography,
  Divider,
  List,
  Avatar,
  Tag,
} from 'antd';
import {
  RiseOutlined,
  FallOutlined,
  WalletOutlined,
  CheckCircleOutlined,
  SearchOutlined,
  CarOutlined,
} from '@ant-design/icons';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import 'moment/locale/ru';
import { useMediaQuery } from 'react-responsive';
import '../css/MainPage.css';

const { Content } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;

const MainPage = () => {
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [balance, setBalance] = useState(0);
  const [totalCompletedOrders, setTotalCompletedOrders] = useState(0);
  const [loading, setLoading] = useState(true);

  const [carHistory, setCarHistory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPeriod, setFilterPeriod] = useState('Последние 12 месяцев');
  const [filteredCarHistory, setFilteredCarHistory] = useState([]);

  moment.locale('ru');

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const unsubscribeFinancials = onSnapshot(
      collection(db, 'transactions'),
      (snapshot) => {
        let incomeSum = 0;
        let expenseSum = 0;
        const monthlyData = {};

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.date) {
            const dateObj = new Date(data.date);
            if (!isNaN(dateObj)) {
              const month = moment(dateObj).format('MMMM YYYY');
              if (!monthlyData[month]) {
                monthlyData[month] = { income: 0, expense: 0 };
              }
              if (data.type === 'income') {
                incomeSum += data.amount || 0;
                monthlyData[month].income += data.amount || 0;
              } else if (data.type === 'expense') {
                expenseSum += data.amount || 0;
                monthlyData[month].expense += data.amount || 0;
              }
            } else {
              console.warn('Некорректная дата:', data.date);
            }
          } else {
            console.warn('Отсутствует дата в записи:', data);
          }
        });

        setTotalIncome(incomeSum);
        setTotalExpense(expenseSum);
        setBalance(incomeSum - expenseSum);
        setLoading(false);
      },
      (error) => {
        console.error('Ошибка при получении данных из финансовой системы:', error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribeFinancials();
    };
  }, []);

  useEffect(() => {
    const unsubscribeArchivedRequests = onSnapshot(
      collection(db, 'archiveRequests'),
      (snapshot) => {
        let completedOrdersCount = snapshot.docs.length;
        setTotalCompletedOrders(completedOrdersCount);
      },
      (error) => {
        console.error('Ошибка при получении архивных заявок:', error);
      }
    );

    return () => {
      unsubscribeArchivedRequests();
    };
  }, []);

  useEffect(() => {
    const unsubscribeRequests = onSnapshot(
      collection(db, 'archiveRequests'),
      (snapshot) => {
        const fetchedCarHistory = snapshot.docs.map((doc) => {
          const data = doc.data();
          let archivedDate = data.archivedDate ? new Date(data.archivedDate) : null;

          if (!archivedDate || isNaN(archivedDate)) {
            archivedDate = new Date();
          }

          return {
            key: doc.id,
            carModel: data.carModel || 'Не указано',
            carDescription: data.issue || 'Описание отсутствует',
            clientName: data.name || 'Не указано',
            requestId: doc.id,
            profit: data.balance || 0,
            date: archivedDate,
          };
        });

        setCarHistory(fetchedCarHistory);
      },
      (error) => {
        console.error('Ошибка при получении архивных заявок:', error);
      }
    );

    return () => {
      unsubscribeRequests();
    };
  }, []);

  useEffect(() => {
    const now = new Date();
    let pastDate = new Date();

    switch (filterPeriod) {
      case 'Последние 3 месяца':
        pastDate.setMonth(now.getMonth() - 3);
        break;
      case 'Последние 6 месяцев':
        pastDate.setMonth(now.getMonth() - 6);
        break;
      case 'Последние 12 месяцев':
        pastDate.setMonth(now.getMonth() - 12);
        break;
      default:
        pastDate.setMonth(now.getMonth() - 12);
    }

    const filteredByDate = carHistory.filter((car) => car.date >= pastDate);

    const filteredBySearch = filteredByDate.filter(
      (car) =>
        car.carModel.toLowerCase().includes(searchTerm.toLowerCase()) ||
        car.clientName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedCarHistory = filteredBySearch.sort((a, b) => b.date - a.date);

    setFilteredCarHistory(sortedCarHistory);
  }, [searchTerm, filterPeriod, carHistory]);

  const statisticsData = [
    {
      icon: <RiseOutlined style={{ color: '#3f8600', fontSize: 36 }} />,
      title: 'Доходы',
      value: totalIncome ? `${totalIncome.toFixed(2)} ₽` : '0.00 ₽',
      color: '#3f8600',
    },
    {
      icon: <FallOutlined style={{ color: '#cf1322', fontSize: 36 }} />,
      title: 'Расходы',
      value: totalExpense ? `${totalExpense.toFixed(2)} ₽` : '0.00 ₽',
      color: '#cf1322',
    },
    {
      icon: (
        <WalletOutlined
          style={{
            color: balance >= 0 ? '#3f8600' : '#cf1322',
            fontSize: 36,
          }}
        />
      ),
      title: 'Баланс',
      value: balance ? `${balance.toFixed(2)} ₽` : '0.00 ₽',
      color: balance >= 0 ? '#3f8600' : '#cf1322',
    },
    {
      icon: <CheckCircleOutlined style={{ color: '#1890ff', fontSize: 36 }} />,
      title: 'Завершенные заявки',
      value: totalCompletedOrders,
      color: '#1890ff',
    },
  ];

  const periodOptions = [
    { label: 'Последние 3 месяца', value: 'Последние 3 месяца' },
    { label: 'Последние 6 месяцев', value: 'Последние 6 месяцев' },
    { label: 'Последние 12 месяцев', value: 'Последние 12 месяцев' },
  ];

  const pieOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c} ₽ ({d}%)',
    },
    legend: {
      orient: 'horizontal',
      bottom: '5%',
      left: 'center',
      textStyle: {
        fontSize: isMobile ? 10 : 14,
      },
      data: ['Доходы', 'Расходы'],
    },
    series: [
      {
        name: 'Финансы',
        type: 'pie',
        radius: isMobile ? ['30%', '60%'] : ['40%', '70%'],
        label: {
          show: true,
          fontSize: isMobile ? 10 : 12,
        },
        data: [
          { value: totalIncome, name: 'Доходы' },
          { value: totalExpense, name: 'Расходы' },
        ],
        color: ['#52c41a', '#ff4d4f'],
      },
    ],
  };

  const [monthlyBalance, setMonthlyBalance] = useState([]);

  useEffect(() => {
    const fetchMonthlyBalance = () => {
      const unsubscribe = onSnapshot(collection(db, 'transactions'), (snapshot) => {
        let incomeSum = 0;
        let expenseSum = 0;
        const monthlyData = {};

        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.date) {
            const dateObj = new Date(data.date);
            if (!isNaN(dateObj)) {
              const month = moment(dateObj).format('MMMM YYYY');
              if (!monthlyData[month]) {
                monthlyData[month] = { income: 0, expense: 0 };
              }
              if (data.type === 'income') {
                incomeSum += data.amount || 0;
                monthlyData[month].income += data.amount || 0;
              } else if (data.type === 'expense') {
                expenseSum += data.amount || 0;
                monthlyData[month].expense += data.amount || 0;
              }
            }
          }
        });

        const sortedMonths = Object.keys(monthlyData).sort(
          (a, b) => new Date(a) - new Date(b)
        );

        const balanceData = sortedMonths.map((month) => ({
          month,
          balance: monthlyData[month].income - monthlyData[month].expense,
        }));

        setMonthlyBalance(balanceData);
      });

      return () => unsubscribe();
    };

    fetchMonthlyBalance();
  }, []);

  const lineOptions = {
    tooltip: {
      trigger: 'axis',
      formatter: '{b}: {c} ₽',
    },
    xAxis: {
      type: 'category',
      data: monthlyBalance.map((item) => item.month),
      axisLabel: {
        fontSize: isMobile ? 10 : 12,
        rotate: isMobile ? 45 : 0,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: isMobile ? 10 : 12,
      },
    },
    series: [
      {
        name: 'Баланс',
        type: 'line',
        data: monthlyBalance.map((item) => item.balance),
        symbolSize: isMobile ? 6 : 10,
      },
    ],
  };

  const renderCarHistoryItem = (car) => (
    <List.Item key={car.key}>
      <Card className="car-history-card" hoverable>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={6} md={6} lg={6}>
            <Avatar size={64} icon={<CarOutlined />} style={{ backgroundColor: '#1890ff' }} />
          </Col>
          <Col xs={24} sm={18} md={18} lg={18}>
            <Title level={5}>{car.carModel}</Title>
            <Text strong>Описание:</Text> <Text>{car.carDescription}</Text>
            <br />
            <Text strong>Клиент:</Text> <Text>{car.clientName}</Text>
            <br />
            <Text strong>Прибыль:</Text>{' '}
            <Tag color={car.profit >= 0 ? 'green' : 'red'}>
              {car.profit >= 0 ? `+${car.profit.toFixed(2)}` : `${car.profit.toFixed(2)}`} ₽
            </Tag>
            <br />
            <Text strong>Дата:</Text> <Text>{moment(car.date).format('DD.MM.YYYY')}</Text>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );

  const renderStatisticCard = (item, index) => (
    <Col xs={24} sm={12} md={6} key={index}>
      <Card className="stat-card" hoverable>
        <Statistic title={item.title} value={item.value} valueStyle={{ color: item.color }} prefix={item.icon} />
      </Card>
    </Col>
  );

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" tip="Загрузка..." />
      </div>
    );
  }

  return (
    <Layout className="main-layout">
      <Content className="main-page-content">
        <div className="wrapper">
          <Title level={2} className="main-page-title">
            Салам Алейкум!
          </Title>

          <Row gutter={[16, 16]} justify="center">
            {statisticsData.map((item, index) => renderStatisticCard(item, index))}
          </Row>

          <Card title="Финансовая статистика" className="chart-card">
            <div className="chart-container">
              <div className="chart-content">
                <ReactECharts option={pieOptions} style={{ width: '100%', height: isMobile ? '250px' : '400px' }} />
              </div>
            </div>
          </Card>

          <Card title="Баланс по месяцам" className="chart-card">
            <div className="chart-container">
              <div className="chart-content">
                <ReactECharts option={lineOptions} style={{ width: '100%', height: isMobile ? '250px' : '400px' }} />
              </div>
            </div>
          </Card>

          <Divider orientation="left" className="section-divider">
            <Title level={4}>История автомобилей</Title>
          </Divider>

          <div className="search-filter-container">
            <Space direction="horizontal" size="large" style={{ width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
              <Input
                placeholder="Поиск"
                prefix={<SearchOutlined />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
                allowClear
                style={{ width: 200 }}
              />
              <Select value={filterPeriod} onChange={(value) => setFilterPeriod(value)} className="filter-select" style={{ width: 200 }}>
                {periodOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Space>
          </div>

          <List itemLayout="vertical" size="large" dataSource={filteredCarHistory} renderItem={renderCarHistoryItem} locale={{ emptyText: 'Нет данных для отображения' }} />
        </div>
      </Content>
    </Layout>
  );
};

export default MainPage;
