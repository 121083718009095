import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { Input, Button, Form, DatePicker, Typography, TimePicker } from 'antd';
import { useSound } from '../SoundContext';
import LoadingSpinner from '../LoadingSpinner';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../auth/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import '../css/RequestForm.css';

const { Title } = Typography;

const RequestForm = () => {
  const { currentUser } = useAuth();
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [occupiedSlots, setOccupiedSlots] = useState({});
  const { playSound } = useSound();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOccupiedSlots = async () => {
      try {
        const requestsCollection = collection(db, 'requests');
        const snapshot = await getDocs(requestsCollection);

        const occupied = snapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          if (data.startDateTime && data.endDateTime) {
            const start = new Date(data.startDateTime);
            const end = new Date(data.endDateTime);
            const dateStr = start.toLocaleDateString('ru-RU');

            if (!acc[dateStr]) {
              acc[dateStr] = [];
            }

            acc[dateStr].push({ start, end });
          }
          return acc;
        }, {});

        setOccupiedSlots(occupied);
      } catch (error) {
        toast.error('Ошибка при загрузке данных: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOccupiedSlots();
  }, []);

  const handleSubmit = async (values) => {
    const { name, phone, carModel, carYear, issue } = values;

    if (!selectedDate || !selectedTime) {
      toast.error('Выберите дату и время.');
      return;
    }

    try {
      const startDateTime = moment(selectedDate)
        .set({
          hour: selectedTime.hour(),
          minute: selectedTime.minute(),
        })
        .toDate();
      const endDateTime = new Date(startDateTime);
      endDateTime.setMinutes(endDateTime.getMinutes() + 60);

      const requestsCollection = collection(db, 'requests');
      await addDoc(requestsCollection, {
        name,
        phone: phone || 'Не указан', // Сохраняем "Не указан", если телефон не введен
        carModel,
        carYear,
        issue,
        status: 'new',
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
      });

      form.resetFields();
      setSelectedDate(null);
      setSelectedTime(null);
      playSound();
      toast.success('Заявка успешно отправлена!');
    } catch (error) {
      toast.error('Ошибка при отправке заявки: ' + error.message);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (i < 10 || i >= 20) {
        hours.push(i);
      }
    }
    return hours;
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ToastContainer />
      <div className="request-form">
        <Title level={2} className="form-title">
          Заявка на автосервис
        </Title>
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Ваше имя"
            name="name"
            rules={[{ required: true, message: 'Введите ваше имя' }]}
          >
            <Input placeholder="Введите ваше имя" />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[{ required: false }]} // Поле не является обязательным
          >
            <Input placeholder="Введите номер телефона" />
          </Form.Item>

          <Form.Item
            label="Модель автомобиля"
            name="carModel"
            rules={[{ required: true, message: 'Введите модель автомобиля' }]}
          >
            <Input placeholder="Введите модель автомобиля" />
          </Form.Item>

          <Form.Item
            label="Год выпуска автомобиля"
            name="carYear"
            rules={[{ required: true, message: 'Введите год выпуска автомобиля' }]}
          >
            <Input placeholder="Введите год выпуска автомобиля" />
          </Form.Item>

          <Form.Item
            label="Описание проблемы"
            name="issue"
            rules={[{ required: true, message: 'Опишите проблему' }]}
          >
            <Input.TextArea placeholder="Опишите проблему" rows={4} />
          </Form.Item>

          <Form.Item label="Дата">
            <DatePicker
              format="DD MMMM YYYY"
              locale={locale}
              onChange={(value) => setSelectedDate(value)}
              disabledDate={disabledDate}
              placeholder="Выберите дату"
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Form.Item>

          {selectedDate && (
            <Form.Item label="Время">
              <TimePicker
                format="HH:mm"
                locale={locale}
                onChange={(value) => setSelectedTime(value)}
                placeholder="Выберите время"
                disabledHours={disabledHours}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" className="submit-button" disabled={!selectedDate || !selectedTime}>
              Отправить заявку
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default RequestForm;
