// src/components/auth/AuthProvider.jsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../../firebase'; // Убедитесь, что путь правильный
import { onAuthStateChanged, signOut } from 'firebase/auth';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('onAuthStateChanged triggered:', user);
      if (user) {
        // Пользователь аутентифицирован через Firebase Auth
        console.log('User is signed in:', user);
        setCurrentUser(user);

        try {
          const idTokenResult = await user.getIdTokenResult(true); // Принудительно обновляем токен
          const role = idTokenResult.claims.role;
          if (role) {
            setUserRole(role);
            console.log(`Role for user UID ${user.uid}: ${role}`);
          } else {
            console.error(`Role not found in custom claims for user UID: ${user.uid}`);
            setUserRole(null);
          }
        } catch (error) {
          console.error("Error fetching user claims:", error);
          setUserRole(null);
        }
      } else {
        console.log('User is signed out.');
        setCurrentUser(null);
        setUserRole(null);
      }

      setLoading(false);  // Завершаем загрузку
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      console.log('Пользователь вышел из системы.');
    } catch (error) {
      console.error('Ошибка при выходе из системы:', error);
    }

    setCurrentUser(null);
    setUserRole(null);
  };

  const value = {
    currentUser,
    userRole,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
