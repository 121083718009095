// src/components/RequestsPage.js

import React, { useState, useEffect, useMemo } from 'react';
import { db } from '../../firebase';
import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  addDoc,
} from 'firebase/firestore';
import { Link } from 'react-router-dom';
import '../css/RequestsPage.css';
import {
  FaTimes,
  FaWrench,
  FaFire,
  FaTachometerAlt,
  FaClock,
} from 'react-icons/fa';
import LoadingSpinner from '../LoadingSpinner';

function RequestsPage() {
  const [requests, setRequests] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [financials, setFinancials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestsCollection = collection(db, 'requests');
    const workersCollection = collection(db, 'workers');
    const financialsCollection = collection(db, 'financials');

    const unsubscribeRequests = onSnapshot(
      requestsCollection,
      (snapshot) => {
        const fetchedRequests = snapshot.docs.map((doc) => {
          const requestData = doc.data();
          return {
            id: doc.id,
            ...requestData,
            startDateTime: requestData.startDateTime
              ? new Date(requestData.startDateTime)
              : null,
            endDateTime: requestData.endDateTime
              ? new Date(requestData.endDateTime)
              : null,
          };
        });

        setRequests(fetchedRequests);
        setLoading(false);
      },
      (error) => {
        console.error('Ошибка при получении заявок:', error);
        setLoading(false);
      }
    );

    const unsubscribeWorkers = onSnapshot(
      workersCollection,
      (snapshot) => {
        const fetchedWorkers = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWorkers(fetchedWorkers);
      },
      (error) => {
        console.error('Ошибка при получении работников:', error);
      }
    );

    const unsubscribeFinancials = onSnapshot(
      financialsCollection,
      (snapshot) => {
        const fetchedFinancials = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFinancials(fetchedFinancials);
      },
      (error) => {
        console.error('Ошибка при получении финансовых данных:', error);
      }
    );

    return () => {
      unsubscribeRequests();
      unsubscribeWorkers();
      unsubscribeFinancials();
    };
  }, []);

  const cancelRequest = async (request, event) => {
    event.preventDefault();
    event.stopPropagation();
    const confirmCancel = window.confirm(
      'Вы уверены, что хотите отменить эту заявку? Она будет перенесена в архив.'
    );
    if (!confirmCancel) return;

    try {
      await addDoc(collection(db, 'archiveRequests'), {
        ...request,
        archivedAt: new Date().toISOString(),
        status: 'canceled',
      });

      await deleteDoc(doc(db, 'requests', request.id));

      alert('Заявка успешно перенесена в архив.');
    } catch (error) {
      console.error('Ошибка при отмене заявки:', error);
      alert('Произошла ошибка при отмене заявки. Попробуйте позже.');
    }
  };

  const calculateBalance = (requestId) => {
    const requestFinancials = financials.filter(
      (financial) => financial.requestId === requestId
    );
    const totalBalance = requestFinancials.reduce((balance, financial) => {
      return financial.type === 'income'
        ? balance + financial.amount
        : balance - financial.amount;
    }, 0);

    return totalBalance ? `${totalBalance} руб.` : 'Не указан';
  };

  const getWorkerNames = (workerIds) => {
    return workerIds
      .map((workerId) => {
        const worker = workers.find((w) => w.id === workerId);
        return worker ? worker.name : 'Неизвестный';
      })
      .join(', ');
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const calculateTimeLeft = (endDateTime) => {
    if (!endDateTime) return null;

    const now = new Date();
    const diff = endDateTime - now;

    if (diff <= 0) {
      return 'Время истекло';
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    let result = '';
    if (days > 0) result += `${days} дн `;
    if (hours > 0) result += `${hours} ч `;
    if (minutes > 0) result += `${minutes} мин`;

    return result.trim();
  };

  const filteredRequests = useMemo(() => {
    const filtered = requests.filter((request) => {
      if (statusFilter !== 'all' && request.status !== statusFilter) {
        return false;
      }
      return request.status !== 'canceled';
    });

    return filtered.sort((a, b) => (b.urgency || 1) - (a.urgency || 1));
  }, [requests, statusFilter]);

  const getDifficultyIcon = (level) => {
    return (
      <FaTachometerAlt
        style={{
          color: '#fff',
          fontSize: '1.5rem',
        }}
      />
    );
  };

  const getUrgencyIcon = (level) => {
    return (
      <FaFire
        style={{
          color: '#fff',
          fontSize: '1.5rem',
        }}
      />
    );
  };

  return (
    <div className="requests-container">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="requests-title">Список заявок</h1>

          <div className="filter-container">
            <label htmlFor="statusFilter">Фильтр по статусам:</label>
            <select
              id="statusFilter"
              value={statusFilter}
              onChange={handleStatusChange}
            >
              <option value="all">Все</option>
              <option value="new">Новые</option>
              <option value="pending">В сервисе</option>
              <option value="completed">Отдана клиенту</option>
            </select>
          </div>

          {filteredRequests.length === 0 ? (
            <p>Нет заявок.</p>
          ) : (
            <div className="requests-wrapper">
              {filteredRequests.map((request) => (
                <Link
                  to={`/request/${request.id}`}
                  key={request.id}
                  className="request-link"
                >
                  <div
                    className={`request-card ${getStatusClass(request.status)}`}
                  >
                    <div className="card-header">
                      <h2>{request.name}</h2>
                      <button
                        className="delete-button"
                        onClick={(event) => cancelRequest(request, event)}
                        title="Отменить заявку"
                      >
                        <FaTimes />
                      </button>
                    </div>
                    <p className={`status ${request.status}`}>
                      {getRussianStatus(request.status)}
                    </p>
                    <div className="car-worker-section">
                      <div className="car-model">
                        <strong>Марка:</strong> {request.carModel || 'Не указана'}
                      </div>
                      <div className="car-year">
                        <strong>Год:</strong> {request.carYear || 'Не указан'}
                      </div>
                      <div className="worker-names">
                        <strong>Работники:</strong>{' '}
                        {getWorkerNames(request.assignedWorkers || [])}
                      </div>
                    </div>
                    {calculateTimeLeft(request.endDateTime) && (
                      <div className="deadline-section">
                        <span className="deadline-label">
                          <FaClock /> Дедлайн:
                        </span>
                        <span className="deadline-time">
                          {calculateTimeLeft(request.endDateTime)}
                        </span>
                      </div>
                    )}
                    <div className="icons-section">
                      <div
                        className="icon-block"
                        style={{
                          background: getGradientByLevel(request.urgency || 1),
                        }}
                      >
                        {getUrgencyIcon(request.urgency || 1)}
                      </div>
                      <div
                        className="icon-block"
                        style={{
                          background: getGradientByLevel(request.difficulty || 1),
                        }}
                      >
                        {getDifficultyIcon(request.difficulty || 1)}
                      </div>
                    </div>
                    <div className="balance-section">
                      <span className="balance">
                        <strong>Баланс:</strong> {calculateBalance(request.id)}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function getRussianStatus(status) {
  switch (status) {
    case 'new':
      return 'Новая';
    case 'pending':
      return (
        <span className="status-icon">
          <FaWrench />
        </span>
      );
    case 'completed':
      return 'Отдана клиенту';
    default:
      return status;
  }
}

// Функция для получения градиента по уровню
function getGradientByLevel(level) {
  const gradients = [
    '',
    'linear-gradient(45deg, #4caf50, #8bc34a)',
    'linear-gradient(45deg, #8bc34a, #cddc39)',
    'linear-gradient(45deg, #ffeb3b, #ffc107)',
    'linear-gradient(45deg, #ff9800, #ff5722)',
    'linear-gradient(45deg, #f44336, #e91e63)',
  ];
  return gradients[level] || '#ccc';
}

// Функция для получения класса статуса заявки
function getStatusClass(status) {
  switch (status) {
    case 'new':
      return 'status-new';
    case 'pending':
      return 'status-pending';
    case 'completed':
      return 'status-completed';
    default:
      return '';
  }
}

export default RequestsPage;
