// src/components/notifications/NotificationCenter.js

import React, { useEffect } from 'react';
import { useNotification } from './NotificationContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationCenter = () => {
  const { notifications, addNotification, removeNotification, toggle, setToggle } = useNotification();

  useEffect(() => {
    if (toggle) {
      toast('Toggle is ON', { toastId: 'toggleOn' });
    } else {
      toast('Toggle is OFF', { toastId: 'toggleOff' });
    }
  }, [toggle]);

  const handleToggle = () => {
    console.log("NotificationCenter - Toggling notifications");
    setToggle(!toggle);
  };

  return (
    <div>
      <button onClick={handleToggle}>Toggle Notifications</button>
      <ToastContainer containerId="notificationsContainer" />
      {/* Отображение уведомлений */}
      {notifications.map(notif => (
        <div key={notif.id}>
          <p>{notif.message}</p>
          <button onClick={() => removeNotification(notif.id)}>Dismiss</button>
        </div>
      ))}
    </div>
  );
};

export default NotificationCenter;
