import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import '../css/CalculationPage.css';
import LoadingSpinner from '../LoadingSpinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import * as XLSX from 'xlsx';

Chart.register(ArcElement, Tooltip, Legend);

function CalculationPage() {
  const [workers, setWorkers] = useState([]);
  const [requests, setRequests] = useState([]); // Добавляем состояние для заявок
  const [category, setCategory] = useState('');
  const [amount, setAmount] = useState('');
  const [history, setHistory] = useState([]);
  const [comment, setComment] = useState('');
  const [balance, setBalance] = useState(0);
  const [income, setIncome] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const [workersLoading, setWorkersLoading] = useState(true);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [requestsLoading, setRequestsLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState({ start: '', end: '' });

  useEffect(() => {
    const workersCollection = collection(db, 'workers');
    const unsubscribeWorkers = onSnapshot(
      workersCollection,
      (snapshot) => {
        const fetchedWorkers = snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
        }));
        setWorkers(fetchedWorkers);
        setWorkersLoading(false);
      },
      (error) => {
        console.error('Ошибка загрузки работников:', error);
        toast.error('Ошибка загрузки работников.', {
          position: 'top-center',
          autoClose: 3000,
        });
        setWorkersLoading(false);
      }
    );

    const transactionsCollection = collection(db, 'transactions');
    const unsubscribeTransactions = onSnapshot(
      transactionsCollection,
      (snapshot) => {
        const fetchedHistory = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setHistory(fetchedHistory);

        let totalIncome = 0;
        let totalExpenses = 0;
        const totalBalance = fetchedHistory.reduce((acc, record) => {
          if (record.type === 'income') {
            totalIncome += record.amount;
            return acc + record.amount;
          } else if (record.type === 'expense') {
            totalExpenses += record.amount;
            return acc - record.amount;
          }
          return acc;
        }, 0);
        setBalance(totalBalance);
        setIncome(totalIncome);
        setExpenses(totalExpenses);
        setTransactionsLoading(false);
      },
      (error) => {
        console.error('Ошибка загрузки истории транзакций:', error);
        toast.error('Ошибка загрузки истории транзакций.', {
          position: 'top-center',
          autoClose: 3000,
        });
        setTransactionsLoading(false);
      }
    );

    // Загрузка заявок
    const requestsCollection = collection(db, 'requests');
    const unsubscribeRequests = onSnapshot(
      requestsCollection,
      (snapshot) => {
        const fetchedRequests = snapshot.docs.map((doc) => ({
          id: doc.id,
          carModel: doc.data().carModel,
        }));
        setRequests(fetchedRequests);
        setRequestsLoading(false);
      },
      (error) => {
        console.error('Ошибка загрузки заявок:', error);
        toast.error('Ошибка загрузки заявок.', {
          position: 'top-center',
          autoClose: 3000,
        });
        setRequestsLoading(false);
      }
    );

    return () => {
      unsubscribeWorkers();
      unsubscribeTransactions();
      unsubscribeRequests();
    };
  }, []);

  const playSound = () => {
    const audio = new Audio('/sounds/money.mp3');
    audio.play();
  };

  const handleAddTransaction = async (type) => {
    if (!amount || !category) {
      toast.error('Пожалуйста, введите сумму и выберите категорию', {
        position: 'top-center',
        autoClose: 3000,
      });
      return;
    }
  
    const transactionRecord = {
      type, // Это доход или расход компании
      amount: parseFloat(amount),
      category,
      comment: category === 'Разное' || category === 'company' ? comment : '',
      date: new Date().toISOString(),
    };
  
    try {
      console.log('Запись транзакции в компанию:', transactionRecord);
  
      // 1. Добавляем запись в транзакции компании
      await addDoc(collection(db, 'transactions'), transactionRecord);
  
      playSound();
  
      setAmount('');
      setComment('');
  
      toast.success('Финансовая запись добавлена!', {
        position: 'top-center',
        autoClose: 3000,
      });
  
      // 2. Проверяем, если категория соответствует работнику, обновляем его личные транзакции
      const workerId = category; // Считаем, что в category передается ID работника
      const workerDocRef = doc(db, 'workers', workerId);
      const workerSnapshot = await getDoc(workerDocRef);
  
      if (workerSnapshot.exists()) {
        const workerData = workerSnapshot.data();
        console.log('Данные работника:', workerData);
  
        // Добавляем запись в личную финансовую историю работника
        let workerTransactionType; // Тип транзакции для работника
  
        if (type === 'expense') {
          // Если это расход компании, то это доход для работника
          workerTransactionType = 'income'; 
        } else {
          // Если это доход компании, то это расход для работника
          workerTransactionType = 'expense';
        }
  
        // Обновляем личную финансовую историю работника
        await addDoc(collection(db, `workers/${workerId}/financials`), {
          type: workerTransactionType, // Это доход или расход для работника
          amount: parseFloat(amount),
          description: comment || (workerTransactionType === 'income' ? 'Доход от компании' : 'Расход от компании'),
          date: new Date().toISOString(),
          auto: true, // Указываем, что это автоматическая запись
        });
  
        console.log('Запись в финансовую систему работника добавлена.');
  
        // 3. Обновляем общую финансовую историю (для отображения на странице сотрудника)
        await addDoc(collection(db, 'financials'), {
          workerId,
          type, // Здесь мы сохраняем оригинальный тип транзакции компании
          amount: parseFloat(amount),
          description: `Операция через систему компании: ${comment || 'обновление баланса'}`,
          date: new Date().toISOString(),
          source: 'company', // Указание на источник транзакции
          auto: true,
        });
  
        toast.success(`Баланс и финансовая запись работника ${workerData.name} обновлены!`, {
          position: 'top-center',
          autoClose: 3000,
        });
      } else {
        console.error('Работник не найден!');
      }
    } catch (error) {
      console.error('Ошибка добавления транзакции или обновления баланса работника:', error);
      toast.error('Ошибка при добавлении транзакции.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };
  const handleDownloadReport = () => {
    const filteredHistory = history.filter((record) => {
      const recordDate = new Date(record.date);
      const startDate = selectedDateRange.start ? new Date(selectedDateRange.start) : null;
      const endDate = selectedDateRange.end ? new Date(selectedDateRange.end) : null;
      return (
        (!startDate || recordDate >= startDate) &&
        (!endDate || recordDate <= endDate)
      );
    });

    const data = filteredHistory.map((record) => ({
      Дата: new Date(record.date).toLocaleString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }),
      Категория: getCategoryName(record.category),
      Комментарий: record.comment ? getUpdatedComment(record.comment) : '',
      Сумма: record.type === 'income' ? `+${record.amount}` : `-${record.amount}`,
    }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Отчет');
    XLSX.writeFile(workbook, `financial_report_${selectedDateRange.start || 'start'}_to_${selectedDateRange.end || 'end'}.xlsx`);
  };

  const doughnutData = {
    labels: ['Доходы', 'Расходы'],
    datasets: [
      {
        data: [income, expenses],
        backgroundColor: ['#4caf50', '#f44336'],
        hoverBackgroundColor: ['#66bb6a', '#ef5350'],
      },
    ],
  };

  const doughnutOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
  };

  const getCategoryName = (categoryId) => {
    switch (categoryId) {
      case 'Разное':
      case 'Запчасти':
      case 'Продукты':
        return categoryId;
      case 'company':
        return 'Компания';
      default:
        const worker = workers.find((worker) => worker.id === categoryId);
        return worker ? worker.name : 'Неизвестная категория';
    }
  };

  const getUpdatedComment = (comment) => {
    // Обновленные регулярные выражения
    const requestIdMatch = comment.match(/заявки ([\w-]+)/);
    const workerIdMatch = comment.match(/\(работник ([\w-]+)\)/);

    if (requestIdMatch && workerIdMatch) {
      const requestId = requestIdMatch[1];
      const workerId = workerIdMatch[1];

      const request = requests.find((req) => req.id === requestId);
      const worker = workers.find((w) => w.id === workerId);

      const carModel = request ? request.carModel : 'Неизвестная машина';
      const workerName = worker ? worker.name : 'Неизвестный работник';

      return `Доход от заявки ${carModel} (работник ${workerName})`;
    }

    return comment;
  };

  if (workersLoading || transactionsLoading || requestsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="calculation-page">
      <ToastContainer />
      <h1>Расчетная система</h1>

      <div className="financial-chart">
        <h2>Финансовая статистика</h2>
        <div className="doughnut-chart">
          <Doughnut data={doughnutData} options={doughnutOptions} />
        </div>
      </div>

      <h2>Общий баланс: {balance.toFixed(2)} руб.</h2>

      <div className="input-section">
        <div className="category-dropdown">
          <select
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="category-select"
          >
            <option value=""> Выберите категорию </option>
            <option value="Разное">Разное</option>
            <option value="Запчасти">Запчасти</option>
            <option value="Продукты">Продукты</option>
            <option value="company">Компания</option>
            {workers.map((worker) => (
              <option key={worker.id} value={worker.id}>
                {worker.name}
              </option>
            ))}
          </select>
        </div>

        {(category === 'Разное' || category === 'company') && (
          <input
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Введите комментарий"
            className="comment-input"
          />
        )}

        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Введите сумму"
          className="amount-input"
        />

        <div className="button-section">
          <button className="plus-button" onClick={() => handleAddTransaction('income')}>
            +
          </button>
          <button className="minus-button" onClick={() => handleAddTransaction('expense')}>
            -
          </button>
        </div>
      </div>

      <h2>История расчетов</h2>
      <div className="history-section">
        {history.length > 0 ? (
          history
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((record) => (
              <div key={record.id} className={`history-item ${record.type}`}>
                <div className="history-details">
                  <p>
                    {new Date(record.date).toLocaleString('ru-RU', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                    })}
                  </p>
                  <p>{getCategoryName(record.category)}</p>
                  {record.comment && (
                    <p className="comment">{getUpdatedComment(record.comment)}</p>
                  )}
                </div>
                <p className={`amount ${record.type}`}>
                  {record.type === 'income' ? `+${record.amount}` : `-${record.amount}`}
                </p>
              </div>
            ))
        ) : (
          <p>Транзакции не найдены.</p>
        )}
      </div>

      <div className="date-filter">
        <label>Промежуток дат:</label>
        <div className="date-picker-container">
          <input
            type="date"
            value={selectedDateRange.start}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, start: e.target.value })}
          />
          <input
            type="date"
            value={selectedDateRange.end}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, end: e.target.value })}
          />
        </div>
      </div>

      <div className="download-report">
        <button onClick={handleDownloadReport} className="download-button">
          Скачать отчет
        </button>
      </div>
    </div>
  );
}

export default CalculationPage;
