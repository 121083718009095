import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc, collection, getDocs, query, where, addDoc } from 'firebase/firestore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/RequestDetailsPage.css';
import LoadingSpinner from '../LoadingSpinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaFire, FaTachometerAlt } from 'react-icons/fa';

function RequestDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [newFinancial, setNewFinancial] = useState({
    type: 'income',
    amount: '',
    description: '',
  });
  const [startDateTime, setStartDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [financials, setFinancials] = useState([]);
  const [companyShare, setCompanyShare] = useState(0);
  const [workerShare, setWorkerShare] = useState(0);
  const [difficulty, setDifficulty] = useState(1);
  const [urgency, setUrgency] = useState(1);
  const [showDifficultyDropdown, setShowDifficultyDropdown] = useState(false);
  const [showUrgencyDropdown, setShowUrgencyDropdown] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const requestDocRef = doc(db, 'requests', id);
        const requestSnapshot = await getDoc(requestDocRef);
        if (requestSnapshot.exists()) {
          const requestData = requestSnapshot.data();
          setRequest({
            id,
            ...requestData,
            startDateTime: requestData.startDateTime
              ? new Date(requestData.startDateTime)
              : null,
            endDateTime: requestData.endDateTime
              ? new Date(requestData.endDateTime)
              : null,
            createdAt: requestData.createdAt
              ? new Date(requestData.createdAt)
              : null,
            difficulty: requestData.difficulty || 1,
            urgency: requestData.urgency || 1,
          });
          setSelectedWorkers(requestData.assignedWorkers || []);
        } else {
          toast.error('Заявка не найдена.', {
            position: 'top-center',
            autoClose: 3000,
          });
          navigate('/requests');
        }
      } catch (error) {
        console.error('Ошибка загрузки заявки:', error);
        toast.error('Произошла ошибка при загрузке заявки.', {
          position: 'top-center',
          autoClose: 3000,
        });
        navigate('/requests');
      }
    };

    const fetchWorkers = async () => {
      try {
        const workersCollection = await getDocs(collection(db, 'workers'));
        setWorkers(
          workersCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
      } catch (error) {
        console.error('Ошибка загрузки работников:', error);
        toast.error('Произошла ошибка при загрузке работников.', {
          position: 'top-center',
          autoClose: 3000,
        });
      }
    };

    const fetchFinancials = async () => {
      try {
        const financialsQuery = query(
          collection(db, 'financials'),
          where('requestId', '==', id)
        );
        const financialsSnapshot = await getDocs(financialsQuery);
        const fetchedFinancials = financialsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFinancials(fetchedFinancials);

        if (fetchedFinancials.length > 0 && selectedWorkers.length > 0) {
          calculateShares(fetchedFinancials);
        }
      } catch (error) {
        console.error('Ошибка загрузки финансовых записей:', error);
      }
    };

    const loadData = async () => {
      await Promise.all([fetchRequest(), fetchWorkers(), fetchFinancials()]);
      setLoading(false);
    };

    loadData();
  }, [id, navigate, selectedWorkers]);

  const calculateShares = (financials) => {
    const totalIncome = financials
      .filter((record) => record.type === 'income')
      .reduce((sum, record) => sum + parseFloat(record.amount), 0);

    const totalExpense = financials
      .filter((record) => record.type === 'expense')
      .reduce((sum, record) => sum + parseFloat(record.amount), 0);

    const netIncome = totalIncome - totalExpense;
    const company = Math.floor(netIncome * 0.5);
    let workersTotal = Math.floor(netIncome * 0.5);

    const perWorker = selectedWorkers.length > 0 ? Math.floor(workersTotal / selectedWorkers.length) : 0;
    const remainder = workersTotal % selectedWorkers.length;

    setCompanyShare(company);
    setWorkerShare(perWorker + (remainder > 0 ? 1 : 0));
  };

  const handleFinancialChange = (e) => {
    setNewFinancial({ ...newFinancial, [e.target.name]: e.target.value });
  };

  const handleAddFinancial = async (e) => {
    e.preventDefault();

    if (!newFinancial.amount || !newFinancial.description) {
      toast.error('Пожалуйста, заполните все поля финансовой записи.', {
        position: 'top-center',
        autoClose: 3000,
      });
      return;
    }

    const financialRecord = {
      requestId: id,
      type: newFinancial.type,
      amount: parseFloat(newFinancial.amount),
      description: newFinancial.description,
      date: new Date().toISOString(),
    };

    try {
      const financialsCollectionRef = collection(db, 'financials');
      await addDoc(financialsCollectionRef, financialRecord);

      setFinancials((prevFinancials) => {
        const updatedFinancials = [...prevFinancials, financialRecord];
        calculateShares(updatedFinancials);
        return updatedFinancials;
      });

      setNewFinancial({
        type: 'income',
        amount: '',
        description: '',
      });

      toast.success('Финансовая запись добавлена успешно!', {
        position: 'top-center',
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Ошибка добавления финансовой записи:', error);
      toast.error('Произошла ошибка при добавлении записи.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  const handleWorkerClick = async (workerId) => {
    let updatedWorkers = [...selectedWorkers];

    if (selectedWorkers.includes(workerId)) {
      updatedWorkers = updatedWorkers.filter((id) => id !== workerId);
    } else {
      updatedWorkers.push(workerId);
    }

    setSelectedWorkers(updatedWorkers);

    try {
      const requestDocRef = doc(db, 'requests', id);
      await updateDoc(requestDocRef, {
        assignedWorkers: updatedWorkers,
      });
      toast.success('Работники успешно обновлены.', {
        position: 'top-center',
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Ошибка обновления работников:', error);
      toast.error('Ошибка при обновлении списка работников.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  const calculateTimeLeft = () => {
    if (!endDateTime) return 'Дедлайн не указан';

    const now = new Date();
    const timeDifference = endDateTime - now;

    if (timeDifference <= 0) {
      return 'Время истекло';
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    let result = '';
    if (days > 0) result += `${days} дн `;
    if (hours > 0) result += `${hours} ч `;
    if (minutes > 0) result += `${minutes} мин`;

    return `Осталось: ${result.trim()}`;
  };

  const handleStartDateTimeChange = (date) => {
    setStartDateTime(date);
  };

  const handleEndDateTimeChange = (date) => {
    setEndDateTime(date);
  };

  const handleCompleteOrder = async () => {
    try {
      // Обновляем статус заявки на 'confirmation'
      await updateDoc(doc(db, 'requests', id), {
        status: 'confirmation',
      });

      // Уведомляем пользователя об успешном выполнении заявки
      toast.success('Заявка успешно выполнена и отправлена на подтверждение!', {
        position: 'top-center',
        autoClose: 3000,
      });

      // Перенаправляем пользователя на страницу подтверждения заявок
      navigate('/confirmations');
    } catch (error) {
      console.error('Ошибка выполнения заявки:', error);
      toast.error('Произошла ошибка при выполнении заявки.', {
        position: 'top-center',
        autoClose: 3000,
      });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="details-container">
      <ToastContainer />

      <h1 className="request-title">Детали заявки</h1>

      <div className="details-card">
        <div className="info-section card">
          <h2>Информация о клиенте</h2>
          <p>
            <strong>Имя:</strong> {request.name}
          </p>
          <p>
            <strong>Телефон:</strong> {request.phone}
          </p>
          <p>
            <strong>Модель авто:</strong> {request.carModel}
          </p>
          <p>
            <strong>Год авто:</strong> {request.carYear}
          </p>
          <p>
            <strong>Описание проблемы:</strong> {request.issue}
          </p>
          <p>
            <strong>Время создания заявки:</strong>{' '}
            {request.createdAt
              ? request.createdAt.toLocaleString('ru-RU')
              : 'Не указано'}
          </p>
        </div>

        <div className="manage-section card">
          <h2>Управление заявкой</h2>
          <div className="status-section">
            <label>
              <strong>Статус:</strong>
            </label>
            <select
              className="status-select"
              value={request.status}
              onChange={(e) =>
                updateDoc(doc(db, 'requests', id), {
                  status: e.target.value,
                })
              }
            >
              <option value="new">Новая</option>
              <option value="pending">В сервисе</option>
              <option value="canceled">Отменено</option>
            </select>
          </div>

          <div className="date-section">
            <div className="date-item">
              <span className="date-label">Дата и время начала:</span>
              <span className="date-value">
                {startDateTime
                  ? startDateTime.toLocaleString('ru-RU')
                  : 'Не указано'}
              </span>
            </div>
            <div className="date-item">
              <span className="date-label">Дата и время окончания:</span>
              <span className="date-value">
                {endDateTime
                  ? endDateTime.toLocaleString('ru-RU')
                  : 'Не указано'}
              </span>
            </div>

            <div className="deadline-section">
              <p>
                <strong>Дедлайн:</strong> {calculateTimeLeft()}
              </p>
            </div>
          </div>

          <div className="button-group">
            <button
              className="button edit-date-button"
              onClick={() => setShowCalendar(!showCalendar)}
            >
              {showCalendar ? 'Отмена' : 'Изменить дату и время'}
            </button>
            <button className="button complete" onClick={handleCompleteOrder}>
              Выполнить заказ
            </button>
          </div>

          {showCalendar && (
            <div className="date-time-picker-container">
              <div className="date-time-picker">
                <label>Дата и время начала:</label>
                <DatePicker
                  selected={startDateTime}
                  onChange={handleStartDateTimeChange}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat="Pp"
                  timeFormat="HH:mm"
                  placeholderText="Выберите дату и время начала"
                />
              </div>
              <div className="date-time-picker">
                <label>Дата и время окончания:</label>
                <DatePicker
                  selected={endDateTime}
                  onChange={handleEndDateTimeChange}
                  showTimeSelect
                  timeIntervals={30}
                  dateFormat="Pp"
                  timeFormat="HH:mm"
                  placeholderText="Выберите дату и время окончания"
                />
              </div>
              <button
                className="button save-date-button"
                onClick={() => {
                  updateDoc(doc(db, 'requests', id), {
                    startDateTime: startDateTime.toISOString(),
                    endDateTime: endDateTime.toISOString(),
                  });
                  setShowCalendar(false);
                }}
              >
                Сохранить
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="details-card">
        <div className="workers-section card">
          <h2>Назначить работников</h2>
          <div className="workers-grid">
            {workers.map((worker) => (
              <div
                key={worker.id}
                className={`worker-item ${
                  selectedWorkers.includes(worker.id) ? 'selected' : ''
                }`}
                onClick={() => handleWorkerClick(worker.id)}
              >
                <p>{worker.name}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="financial-section card">
          <h2>Добавить финансовую запись</h2>
          <form onSubmit={handleAddFinancial} className="financial-form">
            <div className="financial-inputs">
              <select
                name="type"
                value={newFinancial.type}
                onChange={handleFinancialChange}
              >
                <option value="income">Доход</option>
                <option value="expense">Расход</option>
              </select>
              <input
                type="number"
                name="amount"
                value={newFinancial.amount}
                placeholder="Сумма"
                onChange={handleFinancialChange}
                required
              />
              <input
                type="text"
                name="description"
                value={newFinancial.description}
                placeholder="Описание"
                onChange={handleFinancialChange}
                required
              />
            </div>
            <button type="submit" className="button">
              Добавить
            </button>
          </form>
        </div>
      </div>

      <div className="financial-history card">
        <h2 className="section-title">История доходов и расходов</h2>
        <div>
          <p>
            <strong>50% компании:</strong> {companyShare} руб.
          </p>
          <p>
            <strong>50% между рабочими:</strong> {workerShare} руб. (каждому)
          </p>
        </div>
        <div className="financial-records-list">
          {financials.map((financial, index) => (
            <div key={index} className={`financial-record-row ${financial.type}`}>
              <div className="record-date">
                {new Date(financial.date).toLocaleDateString('ru-RU', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
              <div className="record-type">
                {financial.type === 'income' ? 'Доход' : 'Расход'}
              </div>
              <div className="record-amount">{financial.amount} руб.</div>
              <div className="record-description">{financial.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default RequestDetailsPage;